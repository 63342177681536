
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import PandaDownloadBtn from '@/components/downloadQRCode.vue'

import PandaMobileTable from "@/components/mobile-custom/panda-table.vue";

import PandaMobileTWFContainer from "@/components/mobile-custom/panda-table-with-filter-container.vue";

import { AxiosData } from "@/common/http/http";
import { Util ,getPageAPIs} from "@/common/util";
import { CommonApi } from "@/common/api/common";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { WithholdAPI } from "@/common/api/merchantManage/withhold";
export const pageApiList = getPageAPIs(WithholdAPI);

import {
  shareConfirmOptions,
  getCommonList
} from '@/common/config'

// 喇叭类型 0无 1绿米 2闪优
const deviceType2Fields: any = {
  'lvmi': 1,
  'sanyou': 2
}

const deviceField2Type: string[] = ['', 'lvmi', 'sanyou']
const deviceType2ch: string[] = ['', '绿米', '闪优']

const defaultForm: any = {
  device_type: 1,

  subject_id: '',
  resource_id: '',
  serial_number: '',

  pool_table_category_id: '',

  is_status: 1,
}


@Component({
  components: {
    PandaDownloadBtn,
    PandaMobileTable,
    PandaMobileTWFContainer,
  }
})
export default class Withhold extends Vue {
  /** 移动端相关属性 方法 */
  private allMobileChooseFields: any[] = [
    {
      label: '球桌名称',
      field: 'name'
    },
    {
      label: '开关ID',
      field: 'subject_id'
    },
    {
      label: '按钮ID',
      field: 'resource_id'
    },
    {
      label: '球桌分类',
      field: 'pool_table_category_name'
    },
    {
      label: '小程序二维码下载',
      field: 'qrcode_link'
    },
    {
      label: '排序',
      field: 'serial_number'
    },
    {
      label: '状态',
      field: 'table-is-status'
    }
  ];
  /** end 移动端相关属性 方法 */

  private merchantApi: any;

  private deepLoopUtil: any;

  private commonApi = new CommonApi();

  private hornDeviceTypeList: any[] = getCommonList(deviceType2ch, 1)

  constructor() {
    super();
    this.merchantApi = new WithholdAPI();

    this.deepLoopUtil = new DeepLoop();

     this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm, false),
    }

  }
  private show: boolean = false;
  private dialogVisible: boolean = false;
  private dialogImageUrl: string = "";
  private hideUpload: boolean = false;
  private tableData: any[] = [];
  private cover: any = [];
  private form: any = {};
  private activated(): void {
    this.getTable();
    this.commonDeviceList();
    this.poolTableCategory();
  }

  private commonDList: any[] = []

  private commonDeviceList() {
    const form = this.form

    const deviceType = deviceField2Type[form.device_type] || ''


    console.log(deviceType, 'deviceType device')

    return new Promise((resolve, reject) => {
      switch (deviceType) {
        case 'lvmi':
          this.merchantApi.device(
            {
              aqara_id: this.$route.query.aqara_id,
            },
            (res: any) => {
              if (res.data.list) {
                this.commonDList = res.data.list;
              } else {
                this.commonDList = [];
              }

              resolve(res)
            },
            (err: any) => {
              reject(err)
            }
          );
          break;
        case 'sanyou':
          this.merchantApi.sanYouDevice(
            {
              shop_id: this.$route.query.id,
            },
            (res: any) => {
              console.log(res.data, 'res.data')
              if (res.data) {
                this.commonDList = res.data.map((item: any) => {
                  const {
                    mcuid,
                    mcuname
                  } = item || {}
                  const newItem = {
                    ...item,
                    deviceName: mcuname,
                    did: mcuid
                  }

                  return newItem
                });
              } else {
                this.commonDList = [];
              }

              resolve(res)
            },
            (err: any) => {
              reject(err)
            }
          );
          break;
        default:
          return resolve(null)
      }
    })
  }

  private handleChangeDeviceType(val: any) {
    Object.assign(this.form, {
      subject_id: '',
      resource_id: ''
    })
    this.commonDeviceList()
  }

  private dList: any = [];

  private deviceType: string = 'lvmi' // lvmi 绿米
  private device(): Promise<any> {
    const deviceType = this.deviceType

    console.log(deviceType, 'deviceType device')

    return new Promise((resolve, reject) => {
      switch (deviceType) {
        case 'lvmi':
          this.merchantApi.device(
            {
              aqara_id: this.$route.query.aqara_id,
            },
            (res: any) => {
              if (res.data.list) {
                this.dList = res.data.list;
              } else {
                this.dList = [];
              }

              resolve(res)
            },
            (err: any) => {
              reject(err)
            }
          );
          break;
        case 'sanyou':
          this.merchantApi.sanYouDevice(
            {
              shop_id: this.$route.query.id,
            },
            (res: any) => {
              console.log(res.data, 'res.data')
              if (res.data) {
                this.dList = res.data.map((item: any) => {
                  const {
                    mcuid,
                    mcuname
                  } = item || {}
                  const newItem = {
                    ...item,
                    deviceName: mcuname,
                    did: mcuid
                  }

                  return newItem
                });
              } else {
                this.dList = [];
              }

              resolve(res)
            },
            (err: any) => {
              reject(err)
            }
          );
          break;
        default:
          return resolve(null)
      }
    })
  }
  private rList: any = [];
  private resource(refresh: Boolean = false): void {
    const form = this.form

    const deviceType = form.device_type

    // 当不为绿米设备
    if (refresh && deviceType !== 1)  {
      this.$set(this.form, 'resource_id' , '')

      return
    }
    if (this.form.subject_id) {
      this.merchantApi.resource(
        {
          aqara_id: this.$route.query.aqara_id,
          did: [this.form.subject_id],
        },
        (res: any) => {
          this.rList = res.data;
        }
      );
    }
  }
  private getTable(): void {
    this.merchantApi.poolTable(
      {
        shop_id: this.$route.query.id,
        page: this.tablePage.pageNo,
        limit: this.tablePage.pageSize,
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.totalCount = res.data.count;
      }
    );
  }
  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };
  private deleteTable(id: number): void {
    this.$confirm(`确定删除该台桌?`, "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.merchantApi.deleteTable(
        {
          id: id,
        },
        () => {
          Util.showToast("删除成功");
          this.getTable();
        }
      );
    })
  }
  private type: number = 0;
  private async addTable(type: any) {
    this.hideUpload = false;
    this.form = this.deepLoopUtil.deepCopy(defaultForm);
    this.cover = [];

    if (type == 1) {
      // this.deviceType = 'lvmi'

      // await this.device();

      this.type = 1;
      this.show = true;
      if(this.poolTableCategoryList.length){
        this.form.pool_table_category_id = this.poolTableCategoryList[0].id
      }
      this.form.is_status = 1
    } else {
      // const {
      //   lock_id,
      //   horn_type
      //   // lock_data
      // } = type || {}

      // this.deviceType = deviceField2Type[horn_type]

      // this.form.horn_type = horn_type

      // await this.device();

      console.log(type, 'type')

      this.form = this.deepLoopUtil.deepCopy(type, false);

      await this.commonDeviceList()



      this.type = 0;
      this.cover = [
        {
          url: type.cover_url,
          id: type.cover_id,
        },
      ];
      this.resource();

      //  const deviceType = this.deviceType
      // if (deviceType == 'lvmi') {
      //   this.hresource();
      // }

      this.hideUpload = true;
      this.show = true;
    }
  }
  private poolTableCategoryList: any = [];
  private poolTableCategory(): void {
    this.merchantApi.poolTableCategory(
      {
        page: 1,
        limit: 200,
      },
      (res: any) => {
        this.poolTableCategoryList = res.data.list;
      }
    );
  }
  private getOss(file: File, cb: Function) {
    console.log(file);
    if (file.type == "image/jpeg" || file.type == "image/png") {
      this.commonApi.getOss(
        file,
        (res: any) => {
          cb(res);
        },
        () => {}
      );
    } else {
      this.commonApi.getOssvideo(
        file,
        (res: any) => {
          cb(res);
        },
        () => {}
      );
    }
  }
  private fileChange(file: any, fileList: any[]): void {
    this.hideUpload = fileList.length >= 1;
    this.getOss(file.raw, (res: any) => {
      console.log(res);
      const raw = res.data.data.item;
      let obj: any = {
        id: raw.id,
        url: raw.url,
      };
      this.cover.push(obj);
    });
  }
  private handleRemove(file: any): void {
    this.hideUpload = false;
    for (let i in this.cover) {
      if (this.cover[i].id == file.id) {
        this.cover.splice(i, 1);
      }
    }
  }

  private changeTableLoading: boolean = false

  private submit(): void {
    // if (this.cover.length == 0) {
    //   Util.showToast("请上传球桌封面");
    //   return;
    // }
    if(this.cover.length){
      this.form.cover_id = this.cover[0].id;
    }

    this.changeTableLoading = true

    if (this.type == 1) {
      this.form.shop_id = this.$route.query.id;
      this.merchantApi.addTable(this.form, () => {
        this.show = false;
        this.changeTableLoading = false
        this.form = new Util().clearObject(this.form);
        Util.showToast("新增成功");
        this.getTable();
      }, () => {
        this.changeTableLoading = false
      });
    } else {
      this.merchantApi.saveTable(this.form, () => {
        this.show = false;
        this.changeTableLoading = false
        this.form = new Util().clearObject(this.form);
        Util.showToast("修改成功");
        this.getTable();
      }, () => {
        this.changeTableLoading = false
      });
    }
  }
  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private handleClose(done: any): void {
    this.$confirm("确认关闭？")
      .then((_) => {
        done();
      })
      .catch((_) => {});
  }

  private preview(file: any) {
    this.dialogVisible = true;
    this.dialogImageUrl = file.url;
  }
}
