import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class WithholdAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['device', {
            url: '/admin/aqara/device',
            name: 'device-list',
            label: '授权设备列表',
            // hidden: true
        }],
        ['sanYouDevice', {
            url: '/admin/aqara/sanYouDevice',
            name: 'sanYou-device-list',
            label: '授权闪优设备列表',
        }],
        ['resource', {
            url: '/admin/aqara/resource',
            name: 'device-resource',
            label: '设备开关资源',
            // hidden: true
        }],
        ['poolTable', {
            url: '/admin/poolTable/lists',
            name: 'list',
            label: '列表'
        }],
        ['deleteTable', {
            url: '/admin/poolTable/delete',
            name: 'delete',
            label: '球桌删除'
        }],
        ['addTable', {
            url: '/admin/poolTable/add',
            name: 'add',
            label: '球桌添加'
        }],
        ['saveTable', {
            url: '/admin/poolTable/save',
            name: 'edit',
            label: '球桌编辑'
        }],
        ['poolTableCategory', {
            url: '/admin/poolTableCategory/list',
            name: 'pool-category',
            label: '台桌/包厢类型',
            // hidden: true
        }],
    ])

    // 设备列表
    public device(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        // const url = '/order/export'
        this.BasicPost('/admin/aqara/device', {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 闪优设备列表
    public sanYouDevice(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('sanYouDevice').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 设备资源名
    public resource(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        // const url = '/order/export'
        this.BasicPost('/admin/aqara/resource', {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 桌子列表
    public poolTable(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        // const url = '/order/export'
        this.BasicPost('/admin/poolTable/lists', {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    // 桌子删除
    public deleteTable(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        // const url = '/order/export'
        this.BasicPost('/admin/poolTable/delete', {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    // 桌子add
    public addTable(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        // const url = '/order/export'
        this.BasicPost('/admin/poolTable/add', {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 桌子编辑
    public saveTable(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        // const url = '/order/export'
        this.BasicPost('/admin/poolTable/save', {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    //球桌分类列表
    public poolTableCategory(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        // const url = '/order/export'
        this.BasicPost('/admin/poolTableCategory/list', {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}